import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const ProtectionsContainer = styled.section`
    padding: 12em;
    display: flex;
    flex-direction: column;
    gap: 8em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: auto;
`;

const MainHeader = styled.h2`
    //font-size: 4.8rem;
    font-size: clamp(3.4rem, 3vw, 4.8rem);
    margin-bottom: 0.66em;
    max-width: 85%;

    @media screen and (max-width: ${sizes.laptop}) {
        max-width: 100%;
        //font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        //font-size: 3.4rem;
    };
`;

const SubHeader = styled.div`
    font-size: 1.8rem;
    max-width: 80%;

    @media screen and (max-width: ${sizes.laptop}) {
        max-width: 100%;
        font-size: 1.6rem;
    };
`;

const IconGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 8em;

    @media screen and (max-width: ${sizes.tablet}) {
        gap: 6em;
    };
    @media screen and (max-width: 635px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 2.4rem;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 1fr);
        gap: 2.4em;
    };
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.2em;

    @media screen and (max-width: ${sizes.mobileL}) {
        gap: 1em;
        padding-bottom: 1em;
    };
`;

const Icon = styled.img`
    max-width: 40%;

    @media screen and (max-width: ${sizes.laptop}) {
        max-width: 50%;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        max-width: 50%;
    };
`;
const Label = styled.div`
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    font-size: 3.2rem;
    text-align: center;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 2.8rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 2.4rem;
    };
`;

const Protections = ({ strings }) => {
    const icons = strings.ICONS.map(icon => {
        return (
            <IconContainer key={icon.LABEL.split(' ').join('-')}>
                <Icon src={icon.SRC} alt='' />
                <Label>
                    {icon.LABEL}
                </Label>
            </IconContainer>
        );
    });

    return (
        <ProtectionsContainer>
            <HeaderContainer>
                <MainHeader>
                    {strings.HEADER}
                </MainHeader>
                <SubHeader>
                    {strings.SUB_HEADER}
                </SubHeader>
            </HeaderContainer>
            <IconGrid>
                {icons}
            </IconGrid>
        </ProtectionsContainer>
    );
};

export default Protections;