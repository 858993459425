import React from 'react';
import APPLICATION_CONSTANTS from './constants/strings';
import Hero from './shared/Hero';
import SimpleBanner from './shared/SimpleBanner';
import Stats from './shared/Stats'
import ImageText from './shared/ImageText';
import GradientBanner from './shared/GradientBanner';
import CTABanner from './shared/CTABanner';
import MulticolorGrid from './shared/MulticolorGrid';

const Home = () => {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: '<!-- homepage -->' }} />
            <Hero 
                mainHeader={APPLICATION_CONSTANTS.HOME_HERO.MAIN_HEADER}
                subHeader={APPLICATION_CONSTANTS.HOME_HERO.SUB_HEADER}
                src={"/images/home-hero.png"}
                altText={'two people relaxing on a yellow couch with the Oncourse Home Solutions logo above them'}
                layoutType="ImageLeft"
                isHome
            />
            <SimpleBanner string={APPLICATION_CONSTANTS.HOME_SIMPLE_BANNER_STRING}/>
            <Stats statStrings={APPLICATION_CONSTANTS.HOME_STATS_BANNER}/>
            <ImageText
                src="/images/home-image-text.jpg"
                altText={'technician shaking hands with customer'}
                mainHeader={APPLICATION_CONSTANTS.HOME_IMAGETEXT.MAIN_HEADER}
                subHeader1={APPLICATION_CONSTANTS.HOME_IMAGETEXT.SUB_HEADER}
                subHeader2={APPLICATION_CONSTANTS.HOME_IMAGETEXT.SUB_HEADER2}
            />
            <GradientBanner 
                headerString={APPLICATION_CONSTANTS.HOME_GRADIENTBANNER_HEADER}
                cardStrings={APPLICATION_CONSTANTS.ABOUT_GRADIENTBANNER_CARDS}
                type={"large"}
            />
            <MulticolorGrid 
                headerString={APPLICATION_CONSTANTS.ABOUT_MULTICOLOR_GRID.HEADER}
                cardStrings={APPLICATION_CONSTANTS.ABOUT_MULTICOLOR_GRID.CARDS}
            />
            <CTABanner 
                type={APPLICATION_CONSTANTS.CONTACT_US} 
                strings={APPLICATION_CONSTANTS.HOME_CTABANNER_STRINGS} 
            />
        </div>
    );
};

export default Home;