import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const LargeCardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.$numCards}, 1fr);
    column-gap: 2.4em;

    max-width: 1440px;
    margin: auto;

    @media screen and (max-width: 905px) {
        grid-template-columns: repeat(${props => props.$numCards - 1}, 1fr);
        row-gap: 2.4em;
    };
    @media screen and (max-width: 560px) {
        grid-template-columns: repeat(1, 1fr);
    };
`;

const Card = styled.div`
    padding: ${props => (props.$numCards > 2) ? '3.2em 2.4em' : '5.4em'};
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-color: white;
        border-radius: 32px;
    }

    @media screen and (max-width: ${sizes.mobileL}) {
        padding: ${props => (props.$numCards < 3) ? '3.2em 2em' : '2.4em 2em'};
    };
`;

const CardTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.4em;
    align-items: ${props => (props.$numCards > 2) ? 'center' : 'flex-start'};
    position: relative;
    height: 100%;
`;

const ImageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2em;
`;

const Link = styled.a`
    font-size: 1.6rem;
    border-bottom: 2px solid black;
    padding: 0.1em 0em;
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    letter-spacing: .1rem;
    text-decoration: none;
    color: black;

    &:hover {
        color: var(--oncourse-blue);
        border-bottom: 2px solid var(--oncourse-blue);
        cursor: pointer;
    };
`;

const LinkWrapper = styled.div``;

const CardHeader = styled.p`
    font-weight: bold;
    font-size: 2.4rem;
`;

const Content = styled.p`
    font-size: 1.6rem;
    @media screen and (max-width: ${sizes.mobileL}) {
        ${props => (props.$numCards > 2) && 'font-size: 1.4rem;'}
    };
`;

const ImageWrapper = styled.img`
    width: 100%;
`;

const LargeCardLayout = ({ cardStrings, numCards }) => {
    const cards = cardStrings.map((card, index) => {
        return (
            <Card key={`large-card-${index}`} $numCards={numCards}>
                <CardTextContainer $numCards={numCards}>
                    <ImageTextWrapper>
                        <Link href={card?.HREF} target='_blank' style={{border: 'none'}}>
                            <ImageWrapper src={card.SRC} alt={card.ALT} />
                        </Link>
                        {numCards === 2 && <CardHeader>{card.CARD_HEADER}</CardHeader>}
                        <Content $numCards={numCards}>
                            {card.CONTENT}
                        </Content>
                    </ImageTextWrapper>
                    <LinkWrapper>
                        <Link href={card?.HREF} target='_blank'>
                            {card.LINK}
                        </Link>
                    </LinkWrapper>
                </CardTextContainer>
            </Card>
        );
    });

    return (
        <LargeCardContainer $numCards={numCards} >
            {cards}
        </LargeCardContainer>
    )
}

export default LargeCardLayout;