import React from 'react';
import { styled } from 'styled-components';
import Accordion from './Accordion.jsx';
import { sizes } from '../constants/devices.js';

const MainHeader = styled.h1`
    font-size: 4.8rem;
    margin-bottom: 1.33em;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 4rem;
        margin-bottom: 0.8em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
        margin-bottom: 0.4em;
    };
`;

const AccordionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12em 10em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const AccordionSectionContainer = styled.section`
    max-width: 1440px;
    margin: auto;
`;

const AccordionSection = ({ headerString, accordionStrings }) => {
    const accordions = accordionStrings.map((accordionStringSet, index) => {
        return (
            <Accordion key={`accordion-${index}`} accordionStringSet={accordionStringSet} />
        );
    });

    return (
        <AccordionSectionContainer>
            <AccordionsContainer>
                <MainHeader>
                    {headerString}
                </MainHeader>
                {accordions}
            </AccordionsContainer>
        </AccordionSectionContainer>
    );
};

export default AccordionSection;