import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';
import LargeCardLayout from './LargeCardLayout';
import ImageTextV2 from './ImageTextV2';
import ThinCardLayout from './ThinCardLayout';

const BannerContainer = styled.section`
    padding: 12em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6.4em;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-image: url('/images/sunrise-background.svg');
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        gap: 4.8em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
        gap: 4em;
    };
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    width: 100%;
    max-width: 1440px;
`;

const BannerHeader = styled.h1`
    font-size: 4.8rem;
    position: relative;
    text-align: ${props => props.$hasSubHeader ? 'left' : 'center'};

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
`;

const SubHeader = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
    position: relative;
    text-align: left;
    max-width: 700px;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 1.6rem;
    };
`;

const ColumnLayoutContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2.4em;
    max-width: 1440px;
    margin: auto;
    position: relative;

    @media screen and (max-width: 1285px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        row-gap: 2.4em;
    };
    @media screen and (max-width: 635px) {
        grid-template-columns: repeat(1, 1fr);
    };
`;

const Card = styled.div`
    padding: 3.2em;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-color: white;
        border-radius: 32px;
    }
`;

const CardTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    position: relative;
`;

const CardHeader = styled.h2`
    font-size: 9rem;
    color: var(--oncourse-blue);
`;

const CardSubHeader = styled.p`
    font-size: 1.8rem;
    font-weight: 700;

    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 1.6rem;
        max-width: 80%;
    };
`;

const CardContent = styled.p`
    font-size: 1.6rem;

    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 1.4rem;
    };
`;

const GradientBanner = ({ headerString, subHeaderString, cardStrings, type }) => {
    const cards = cardStrings.map((card, index) => {
        return (
            <Card key={`fourColumnLayout-${index}`}>
                <CardTextContainer>
                    <CardHeader>
                        {card.MAIN_HEADER}
                    </CardHeader>
                    <CardSubHeader>
                        {card.SUB_HEADER}
                    </CardSubHeader>
                    <CardContent>
                        {card.CONTENT}
                    </CardContent>
                </CardTextContainer>
            </Card>
        );
    });

    const header = (
        <BannerHeader $hasSubHeader={!!subHeaderString}>
            {headerString}
        </BannerHeader>
    );

    const largeHeader = (
        <HeaderContainer>
            <BannerHeader $hasSubHeader={!!subHeaderString}>
                {headerString}
            </BannerHeader>
            <SubHeader>
                {subHeaderString}
            </SubHeader>
        </HeaderContainer>

    );

    const fourColumnLayout = (
        <ColumnLayoutContainer>
            {cards}
        </ColumnLayoutContainer>
    );

    const largeCardLayout = (
        <LargeCardLayout
            cardStrings={cardStrings}
            numCards={cardStrings.length}
        />
    );

    const imageTextLayout = (
        <ImageTextV2 strings={cardStrings} />
    );

    const thinCardLayout = (
        <ThinCardLayout cardStrings={cardStrings}/>
    );

    let component;

    switch(type) {
        case("large"):
            component = largeCardLayout;
            break;
        case("imagetext"):
            component = imageTextLayout;
            break;
        case("thin"):
            component = thinCardLayout;
            break;
        default:
            component = fourColumnLayout;
            break;
    };

    return (
        <BannerContainer>
            {subHeaderString && largeHeader}
            {headerString && !subHeaderString && header}
            {component}
        </BannerContainer>
    );
};

export default GradientBanner;