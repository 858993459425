import React from 'react';
import { styled } from 'styled-components';
import APPLICATION_CONSTANTS from '../constants/strings';
import { useMediaQuery } from 'react-responsive';
import { devices, sizes } from '../constants/devices';
import CloseIcon from "@mui/icons-material/Close";
import Button from './Button';
import { NavLink } from 'react-router-dom';

const MobileNavContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4.8em;
    z-index: 1;
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.07);


    @media screen and (max-width: ${sizes.mobileL}) {
        height: 100%;
        justify-content: flex-start;
    };
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 12em;
    border-bottom: 1px solid var(--background-gray);

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 2em 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 1.6em 2.4em;

        img {
            max-height: 2.4em;
        }
    };
`;

const Options = styled.div`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 4em;
    font-size: 1.6rem;
    font-weight: 700;
    padding-left: 7.5em;
    padding-bottom: 4.8em;
    margin: 0;

    .nav-link {
        color: black;
    };
    .nav-link.active {
        color: var(--oncourse-blue) !important;
    };
    .nav-link:hover {
        color: var(--oncourse-blue) !important;
    };

    @media screen and (max-width: ${sizes.laptop}) {
        padding-left: 3.75em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding-left: 1.5em;
        padding-bottom: 1.5em;
    };
`;

const OptionWrapper = styled.div``;

const ButtonWrapper = styled.div`
    ${props => props.$isMobile && 'padding: 2.4em'};
    
    button {
        ${props => props.$isMobile && 'width: 100%'};
    }
`;

const ImageWrapper = styled.img`
    cursor: pointer;
`;

const MobileNav = ({ handleChange }) => {
    const isMobile = useMediaQuery({ query: devices.mobileL });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    };

    const options = APPLICATION_CONSTANTS.NAV_OPTIONS.map(option => {
        const path = '/' + option.toLocaleLowerCase().split(' ').join('-');
        return (
            <OptionWrapper key={`mobilenav-${option.toLocaleLowerCase().split(' ').join('-')}`}>
                <NavLink
                    to={path}
                    className={'nav-link'}
                    onClick={handleClick}
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "var(--oncourse-blue)" : "black",
                            borderBottom: isActive ? "3px solid var(--oncourse-blue)" : "none",
                            textDecoration: "none"
                        };
                    }}
                >
                    {option}
                </NavLink>
            </OptionWrapper>
        );
    });

    !isMobile && options.push(
        <ButtonWrapper key={`mobilenav-contact`} $isMobile={false}>
            <Button 
                color={APPLICATION_CONSTANTS.COLORS.ONCOURSE_BLUE} 
                string={APPLICATION_CONSTANTS.CONTACT_US}
            /> 
        </ButtonWrapper>
    );

    const logoNavLink = (
        <NavLink to={'/'} onClick={handleClick}>
            <ImageWrapper src={'/images/logo-sm.png'} alt="logo" height="60" />
        </NavLink>
    );

    return (
        <MobileNavContainer>
            <HeaderContainer>
                {logoNavLink}
                <CloseIcon 
                    onClick={handleChange} 
                    fontSize={"large"}
                    sx={{
                        cursor: 'pointer',
                        fontSize: '2.6rem'
                      }}
                />
            </HeaderContainer>
            <Options>
                {options}
            </Options>
            {isMobile && 
                <ButtonWrapper $isMobile>
                    <Button 
                        color={APPLICATION_CONSTANTS.COLORS.ONCOURSE_BLUE} 
                        string={APPLICATION_CONSTANTS.CONTACT_US}
                    /> 
                </ButtonWrapper>}
        </MobileNavContainer>
    );
};

export default MobileNav;