import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const ColumnLayoutContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 6em;
    color: ${props => props.$styles.backgroundColor === 'white' ? 'black' : 'white'};
    max-width: 1440px;
    margin: auto;

    @media screen and (max-width: ${sizes.tablet}) {
        display: flex;
        flex-direction: column;
        gap: 4.8em;
    };
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6em;
`;

const MainHeader = styled.p`
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    font-size: 3.2rem;
    max-width: 275px;

    @media screen and (max-width: ${sizes.tablet}) {
        max-width: none;
    };
    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 2.8rem;
    };
`;

const SubHeader = styled.p`
    font-size: clamp(1.6rem, 1vw, 1.8rem);
    max-width: 350px;

    @media screen and (max-width: ${sizes.tablet}) {
        max-width: none;
    };
`;

const Icon = styled.img`
    height: 50px;
    margin-bottom: 1.6em;
`;

const ColumnLayout = ({ columnHeaderColor, strings, styles }) => {
    const columns = strings.map(group => {
        return (
            <div>
                {group.SRC && <Icon src={group.SRC} alt={group.MAIN_HEADER} />}
                <TextContainer key={group.MAIN_HEADER}>
                    <MainHeader style={columnHeaderColor && {color: columnHeaderColor}}>
                        {group.MAIN_HEADER}
                    </MainHeader>
                    <SubHeader>
                        {group.SUB_HEADER}
                    </SubHeader>
                </TextContainer>
            </div>
        );
    });

    return (
        <ColumnLayoutContainer $styles={styles}>
            {columns}
        </ColumnLayoutContainer>
    );
};

export default ColumnLayout;