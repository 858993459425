import React from 'react';
import { styled } from 'styled-components';
import APPLICATION_CONSTANTS from '../constants/strings.js';
import { devices, sizes } from '../constants/devices.js';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';

const FooterContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 4.8em;
    max-width: 1440px;
    margin: auto;
    padding: 7.5em 12em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
        gap: 3.2em;
    };
`;

const LogoLinksContainer = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${sizes.laptop}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        gap: 3.2em;
    };
`;

const ContactInfo = styled.div`
    display: flex;
    gap: 1.2em;
`;

const Header = styled.h3`
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0;
`;

const Text = styled.p`
    font-size: 1.4rem;
`;

const ContactAndBBB = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${sizes.laptop}) {
        flex-direction: column-reverse;
    }
`;

const ContactInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.6em;

    @media screen and (max-width: ${sizes.laptop}) {
        border-top: 1px solid black;
        padding-top: 4.8em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding-top: 3.2em;
    };
`;

const Disclaimers = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
`

const StripeFAQ = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
`

const SocialMediaContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.$isTablet ? "column" : "row"};
    gap: ${props => props.$isTablet && '2.4em'};
    justify-content: space-between;
    border-top: 1px solid black;
    border-bottom: ${props => props.$isTablet && '1px solid black'};
    padding: 2.4em 0em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 3.2em 0em;
    };
`;

const SocialLinks = styled.div`
    display: flex;
    gap: 2.4rem;
`;

const Options = styled.div`
    list-style-type: none;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    gap: 3.6em;
    font-weight: 700;
    margin: 0;
    padding-left: 4em;
    white-space: nowrap;

    .nav-link {
        color: black;
    };
    .nav-link.active {
        color: var(--oncourse-blue) !important;
    };
    .nav-link:hover {
        color: var(--oncourse-blue) !important;
    };

    @media screen and (max-width: ${sizes.tablet}) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 2.4em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        gap: 1.5em;
    };
    @media screen and (max-width: ${sizes.laptop}) {
        padding: 0;
    };
`;

const ImageWrapper = styled.img`
    max-height: 100px;
`;

const IconWrapper = styled.img``;

const ExternalLink = styled.a`
    cursor: pointer;
`;

const BBBLink = styled.a`
    position: relative;

    overflow: hidden;
    display: block;

    width: 200px;
    height: 76px;
    margin: 0;
    padding: 0;

    color: #005a78;
    text-align: center;
    text-decoration: none;

    img {
        position: absolute;
        top: 0;
        left: 0;

        max-width: 200%;
        margin-left: 0;

        border: 0;
    }

    &:hover img {
        margin-left: -200px;
    }
    
    @media screen and (max-width: ${sizes.laptop}) {
        margin-bottom: 40px;
    }
`;

const Footer = (props) => {
    const isTablet = useMediaQuery({ query: devices.tablet });
    const footerStrings = APPLICATION_CONSTANTS.FOOTER_STRINGS;

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    };

    const options = APPLICATION_CONSTANTS.NAV_OPTIONS.map(option => {
        const path = '/' + option.toLocaleLowerCase().split(' ').join('-');
        return (
            <NavLink
                key={`footer-${option.toLocaleLowerCase()}`}
                to={path}
                className={'nav-link'}
                onClick={handleClick}
                style={({ isActive }) => {
                    return {
                        color: isActive ? "var(--oncourse-blue)" : "black",
                        borderBottom: isActive ? "3px solid var(--oncourse-blue)" : "none",
                        textDecoration: "none"
                    };
                }}
            >
                {option}
            </NavLink>
        );
    });

    const socialLinks = (
        <SocialLinks>
            <ExternalLink href={'https://www.linkedin.com/company/oncourse-home-solutions/'} target='_blank'>
                <IconWrapper src={'/images/linkedin-icon.svg'} alt='LinkedIn' />
            </ExternalLink>
            {/*
            <ExternalLink>
                <IconWrapper src={'/images/instagram-icon.svg'} alt='Instagram' />
            </ExternalLink>
            <ExternalLink>
                <IconWrapper src={'/images/twitter-icon.svg'} alt='Twitter' />
            </ExternalLink>
            */}
            <ExternalLink href={'https://www.facebook.com/OncourseHomeSolutions'} target='_blank'>
                <IconWrapper src={'/images/facebook-icon.svg'} alt='Facebook' />
            </ExternalLink>
        </SocialLinks>
    );

    return (
        <FooterContainer>
            <LogoLinksContainer>
                <NavLink to={'/'} onClick={handleClick}>
                    <ImageWrapper src={'/images/logo-sm.png'} alt='link to homepage' />
                </NavLink>
                <Options>
                    {options}
                    <NavLink
                        to={'/privacy'}
                        className={'nav-link'}
                        onClick={handleClick}
                        style={({ isActive }) => {
                            return {
                                color: isActive ? "var(--oncourse-blue)" : "black",
                                borderBottom: isActive ? "3px solid var(--oncourse-blue)" : "none",
                                textDecoration: "none"
                            };
                        }}
                    >
                        Privacy Policy
                    </NavLink>
                </Options>
            </LogoLinksContainer>
            <ContactAndBBB>
                <ContactInfoContainer>
                    {isTablet && <Header>Contact</Header>}
                    <ContactInfo>
                        <IconWrapper src={'/images/phone-icon.svg'} alt='phone number' />
                        <Text>
                            {footerStrings.PHONE}
                        </Text>
                    </ContactInfo>
                    <ContactInfo>
                        <IconWrapper src={'/images/mail-icon.svg'} alt='mail' />
                        <Text>
                            {footerStrings.EMAIL}
                        </Text>
                    </ContactInfo>
                    <ContactInfo>
                        <IconWrapper src={'/images/location-icon.svg'} alt='location' />
                        <Text>
                            {footerStrings.LOCATION}
                        </Text>
                    </ContactInfo>
                </ContactInfoContainer>
                <BBBLink
                    href='https://www.bbb.org/us/il/naperville/profile/home-warranty-plans/oncourse-home-solutions-0654-88205178/#sealclick'
                    id='bbblink'
                    className='bbblink'
                    target='_blank'
                    rel='nofollow'
                >
                    <img
                        src='https://seal-chicago.bbb.org/logo/rbhzbul/bbb-88205178.png'
                        alt='American Water Resources, LLC BBB Business Review'
                    />
                </BBBLink>
            </ContactAndBBB>
            <Disclaimers>
                {footerStrings.DISCLAIMERS.map(disclaimer => (
                    <Text key={`footer-${disclaimer}`}>{disclaimer}</Text>
                ))}
                {props.isHomeowners && <StripeFAQ>
                    <Text><strong>Unfamiliar charge on your credit card or bank statement? (American Water Resources Customers)</strong></Text>
                    <Text>Due to a recent change in our payment processor, the way our name appears on a customer's credit card or bank statement changed from "American Water Resources" or "AM Water Resources" to "Oncourse* AWR".</Text>
                    <Text>We apologize for any confusion this change has caused. The descriptor has been changed to "OH* AMER WATER RESOURC" or "AMERICAN WATER RESOURC" and all charges after May 9, 2024 will appear with these descriptors on your statement.</Text>
                    <Text>If you have any questions or notice any discrepancies, please contact our customer support team for assistance.</Text>
                    <Text><strong>Unfamiliar charge on your credit card or bank statement? (Pivotal Home Solutions Customers)</strong></Text>
                    <Text>Due to a recent change in our payment processor, the way our name appears on a customer's credit card or bank statement changed from "Pivotal Home Solutions" to "Oncourse* PHS" or "Oncourse* AWR".</Text>
                    <Text>We apologize for any confusion this change has caused. The descriptor has been changed to "OH*PIVOTAL HOME SOL." or "PIVOTAL HOME SOLUTIONS" and all charges after May 9, 2024 will appear with these descriptors on your statement.</Text>
                    <Text>If you have any questions or notice any discrepancies, please contact our customer support team for assistance.</Text>
                </StripeFAQ>}
            </Disclaimers>
            <SocialMediaContainer $isTablet={isTablet}>
                {isTablet ? <Header>Media</Header> : <Text>{footerStrings.COPYRIGHT}</Text>}
                {socialLinks}
            </SocialMediaContainer>
            {isTablet && <Text>{footerStrings.COPYRIGHT}</Text>}
        </FooterContainer>
    );
};

export default Footer;