import React, { useState } from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';
import APPLICATION_CONSTANTS from '../constants/strings';
import { devices } from '../constants/devices';
import { useMediaQuery } from 'react-responsive';
import Video from './Video';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TestimonialsContainer = styled.section`
    padding: 12em;
    background-color: var(--background-gray);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.8em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        gap: 3.2em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };

`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4em;
    text-align: center;
`;

const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.4em;
    max-width: 1440px;
    margin: auto;
    margin-top: 2em;

    @media screen and (max-width: ${sizes.tablet}) {
        grid-template-columns: repeat(1, 1fr);
        gap: 3.2em;
        margin-top: 0em;
    };
`;

const Header = styled.h2`
    font-size: 4.8rem;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
`;

const SubHeader = styled.p`
    font-size: 1.8rem;
    font-weight: bold;

    @media screen and (min-width: ${sizes.laptopL}) {
        max-width: 70%;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 1.6rem;
    };
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4.8em 2.4em;
    gap: 1rem;
    border-radius: 18px;
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.07);
    background-color: white;

    @media screen and (max-width: ${sizes.tablet}) {
        padding: 4.8em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const FilterContainer = styled.div`
    display: flex;
    gap: 2.4em;
`;

const Filter = styled.button`
    padding: 1.2rem 2.4rem;
    font-size: 1.4rem;
    border-radius: 2px;
    border: 1px solid ${props => props.$isActive ? 'var(--oncourse-blue)' : 'black'};
    font-weight: bold;
    cursor: pointer;
    background-color: ${props => props.$isActive ? 'var(--oncourse-blue)' : 'transparent'};
    color: ${props => props.$isActive ? 'white' : 'black'}
`;

const StarsContainer = styled.div`
    display: flex;
    gap: 0.3rem;
`;

const Star = styled.img``;

const Quote = styled.div`
    text-align: center;
    font-size: 1.6rem;
`;

const Name = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
`;

//  const Pagination = styled.img``;

const SelectWrapper = styled.div`
    .MuiInputBase-root {
        border-radius: 2px;
    }
    .MuiSelect-select {
        background-color: var(--oncourse-blue);
        color: white;
        font-weight: 700;
        padding-top: 0.7em;
        position: relative;
        border-radius: 2px !important;
    }
    .MuiSelect-icon {
        position: absolute;
        top: 0;
        width: 1.4em;
        height: 100%;
        color: white;
    }
`;

const Testimonials = ({ strings }) => {
    const [activeFilter, setActiveFilter] = useState(APPLICATION_CONSTANTS.AWR);
    const phsVideoSrc = "https://player.vimeo.com/video/742357863?h=0e2d9cca23&color=34C954&title=0&byline=0&portrait=0";
    const isTablet = useMediaQuery({ query: devices.tablet });
    const isMobileL = useMediaQuery({ query: '(max-width: 600px)' });

    const handleClick = (e) => {
        setActiveFilter(e.target.innerText);
    };

    const handleChange = (e) => {
        setActiveFilter(e.target.value);
    }

    const cards = strings.CARDS.map((card, index) => {
        let stars = [];
        for(let i=0; i<card.STARS; i++) {
            stars.push(<Star key={`active-star-${i}`} src={'/images/star-active.svg'} alt={''} />);
        };
        for(let i=0; i<(5 - card.STARS); i++) {
            stars.push(<Star key={`active-star-${i}`} src={'/images/star-inactive.svg'} alt={''} />)
        };

        return (
            <Card key={`testimonial-${index}`}>
                <StarsContainer>
                    {stars}
                </StarsContainer>
                <Quote>
                    {card.QUOTE}
                </Quote>
                <Name>
                    {card.NAME}
                </Name>
            </Card>
        );

    })

    const filters = (
        <FilterContainer>
            <Filter $isActive={activeFilter === APPLICATION_CONSTANTS.AWR} onClick={handleClick}>
                {APPLICATION_CONSTANTS.AWR}
            </Filter>
            <Filter $isActive={activeFilter === APPLICATION_CONSTANTS.PHS} onClick={handleClick}>
                {APPLICATION_CONSTANTS.PHS}
            </Filter>
        </FilterContainer>
    );

    const selectFilters = (
        <SelectWrapper>
            <Select
                value={activeFilter}
                size="small"
                onChange={handleChange}
                sx={{ width: '100%', fontSize: '1.4rem' }}
                renderValue={() => activeFilter}
                IconComponent={ExpandMoreIcon}
            >
                <MenuItem style={{ fontSize: '1.4rem'}} value={APPLICATION_CONSTANTS.AWR}>
                    {APPLICATION_CONSTANTS.AWR}
                </MenuItem>
                <MenuItem style={{ fontSize: '1.4rem'}} value={APPLICATION_CONSTANTS.PHS}>
                    {APPLICATION_CONSTANTS.PHS}
                </MenuItem>
            </Select>
        </SelectWrapper>
    );

    return (
        <TestimonialsContainer>
            <HeaderContainer>
                <Header>
                    {strings.MAIN_HEADER}
                </Header>
                <SubHeader>
                    {strings.SUB_HEADER}
                </SubHeader>
            </HeaderContainer>
            {/* {isMobileL ? selectFilters : filters} */}
            <CardContainer>
                {cards}
            </CardContainer>
            {/* <Pagination src={'/images/pagination.svg'} alt={''} />  */}
            {!isTablet && <Video src={phsVideoSrc} />}
        </TestimonialsContainer>
    );
};

export default Testimonials;