import React from 'react';
import { styled } from 'styled-components';
import APPLICATION_CONSTANTS from './constants/strings';
import { sizes } from './constants/devices';

const PrivacyContainer = styled.div`
  padding: 6em;
  display: flex;
  flex-direction: column;
  font-size: 1.6em;
  gap: 2em;
  position: relative;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    pointer-events: none;
    background-image: url('/images/sunrise-background.svg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }

  @media screen and (max-width: ${sizes.laptop}) {
    padding: 3em;
  };
  @media screen and (max-width: ${sizes.tablet}) {
    gap: 2em;
  };
  @media screen and (max-width: ${sizes.mobileL}) {
    padding: 4em;
    gap: 1em;
  };
`;

const PrivacyHeader = styled.div`
    background-image: url(/images/contact-background.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    padding: 12em 0em;

    @media screen and (max-width: ${sizes.tablet}) {
        padding: 6em 0em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 0em;
    };
`;

const MainHeader = styled.h1`
    font-size: clamp(4.4rem, 6vw, 12rem);
    line-height: 1em;
    color: #fff;
    text-align: center;
    margin: 0px;
`;

const Privacy = () => {
    return (
      <section>
        <PrivacyHeader>
          <MainHeader>{APPLICATION_CONSTANTS.PRIVACY_HEADER}</MainHeader>
        </PrivacyHeader>
        <PrivacyContainer>
          <p><strong>Last Updated: December 23, 2022</strong></p>
          <h2>Introduction</h2>
          <p>American Water Resources, LLC, American Water Resources of Florida, LLC, American Water Resources of Texas, LLC, and Pivotal Home Solutions, LLC (collectively, “AWR/PHS,” “we” or “us”) are serious about protecting your privacy. We understand your concerns with regard to how information about you is used and we appreciate your trust that we will treat your information responsibly. This privacy policy (“Privacy Policy”) describes what information we collect about you, how we collect it, how we use it, with whom we may share it, and what choices you have regarding our use of your information.</p>
          <p>This Privacy Policy is incorporated into and is part of the <strong><a href="https://www.yourhomesolutions.com/pages/terms-and-conditions" target="_blank" rel="noreferrer" title="Terms of Use">Terms of Use</a></strong> of the AWR/PHS web sites (including, <strong><a href="https://awrusa.com/" target="_blank" rel="noreferrer">www.awrusa.com</a></strong>, <strong><a href="https://www.awrusa.com/www.yourhomesolutions.com" target="_blank" rel="noreferrer">www.yourhomesolutions.com</a></strong>, or any replacement site, collectively, the “Site”).</p>
          <p>We encourage you to become familiar with the terms and conditions of both this Privacy Policy and the <a href="https://www.yourhomesolutions.com/pages/terms-and-conditions" target="_blank" rel="noreferrer" title="Terms of Use"><strong>Terms of Use</strong></a>. By accessing and using the Site, you agree that you have read and understand this Privacy Policy and you accept and consent to the privacy practices (and any uses and disclosures of information about you) that are described in this Privacy Policy.</p>
          <p><strong>Updates to This Policy</strong></p>
          <p>We may revise this Privacy Policy from time to time. Changes will be posted to this page and we encourage you to review this Privacy Policy from time to time. If you do not agree with our Privacy Policy, you should cease use of your Site immediately.</p>
          <p><strong>Children's Data</strong></p>
          <p>We do not knowingly collect personal information from children under the age of 18. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at <strong><a href="mailto:privacy@oncoursehome.com">privacy@oncoursehome.com</a>.</strong></p>
          <p><strong>What Information Do We Collect and How Do We Use It?</strong></p>
          <p>We may collect certain identifying information from or about you in connection with your use of or submissions to the Site (collectively, the “Collected Information”). Collected information includes both information you provide us and information we automatically collect when you visit the Site.</p>
          <p><strong>Information You Provide Us</strong></p>
          <p>You may provide us with your name and contact information, such as your email address, mailing address, or telephone number when you request information through the Site. If you access your account or pay your bill through the Site, you may also provide information such as your account number, credit card information and bank account information. If you inquire about or apply with us through the Site, you may provide us with information on your educational background, employment history or current or previous compensation.</p>
          <p><strong>Information We Automatically Collect</strong></p>
          <p>In addition to the types of information we may collect, AWR/PHS may retain the content of and metadata regarding any correspondence between you and AWR/PHS or our representatives, regardless of the mode of communication by which such correspondence was made. This information helps us improve the Site and the online media, content, materials, opportunities and services that we feature or describe on the Site and to more effectively and efficiently respond to both current and future inquiries.</p>
          <p>The servers used to operate the Site may collect certain data pertaining to you and the equipment and communications method you use to access the internet and the Site. For security reasons and to confirm the integrity of our data, AWR/PHS may combine components of this data with other sources of information which may identify you. Unless otherwise described in this Privacy Policy or our Terms of Use, such identifying information will be used solely for our internal business purposes. In addition, the information we collect may reveal such things as the Internet Protocol (“IP”) address assigned to your computer, specific pages that you accessed on the Site or immediately prior to visiting the Site and the length of time you spent at the Site. The purposes for which this information is collected and used include facilitating Site operation and system administration, generating aggregate, non-identifiable statistical information, monitoring and analyzing Site traffic and usage patterns and improving the content and content delivery with regard to the Site and the online media, content, materials, opportunities and services that we describe or make available on the Site.</p>
          <p>Our Site may at any time, use “cookies” to help us recognize visitors when they return to the Site or as they move among the different portions of the Site.</p>
          <p><strong>Personal Information Collection Summary </strong></p>
          <p>We collect the following categories of information from you:</p>
          <ul>
            <li>Personal information and other identifiers, which include your real name, your alias, postal address, email address, account name or online identifier, signature, telephone number, employment history, education history, Internet Protocol address;</li>
            <li>Internet or other similar network activity, including your interactions with our website;</li>
            <li>Sensory data in the form of audio messages; and</li>
            <li>Inferences drawn from other personal information such as the information saved on your profile reflecting your user preferences and your user behavior on our website.</li>
          </ul>
          <p><strong>With Whom Do We Share Information That We Collect? </strong></p>
          <p>AWR/PHS may, as appropriate, transfer Collected Information, whether solicited or unsolicited, to our offices throughout the country, catalog and add such Collected Information to our databases, transmit such Collected Information to our affiliates and contractors and, to the extent necessary to accomplish the purpose for which information was submitted, to other third parties (e.g., to the relevant financial institutions in making payments).</p>
          <p>We may from time to time use a number of trusted business partners in delivering the online media, content, materials and opportunities available on or through the Site to you. To the extent necessary for purposes of communicating with you or fulfilling your requests for our products or services, or your subscriptions to such media, content and materials, or to market products and services, or to improve our products and services, we may share information about you with these business partners. We may also produce reports on Site traffic or usage patterns and share these reports with our business partners and others, but the information contained in these reports is anonymous and does not allow identification of any specific individual. Other than as expressly provided in this Privacy Policy, any Collected Information we obtain about you will be reported outside our organization only in aggregated formats and will not be distributed in a manner that will identify or be attributable to any particular or specific individual or company.</p>
          <p>AWR/PHS may disclose information about you if we become subject to a subpoena or court order or if we are otherwise legally required to disclose such information. We may also use and disclose information about you to establish or exercise our legal rights, to enforce the&nbsp;<a href="https://www.yourhomesolutions.com/pages/terms-and-conditions" target="_blank" rel="noreferrer" title="Terms of Use"><strong>Terms of Use</strong></a>, to assert and defend against legal claims or to investigate, prevent or take other action regarding actual or suspected illegal or fraudulent activities or potential threats to the physical safety or well-being of any person.</p>
          <p>AWR/PHS may disclose your personal information as part of a corporate business transaction such as a merger or acquisition, divestiture, sale of company assets, or in the event of insolvency, bankruptcy, or receivership. In these cases, personal information could be transferred to third-parties as a business asset. We may also share information with banks and similar entities for purposes of transferring loans in connection with your device financing.</p>
          <p>Please see below for more information on the types of information we share with third-parties.</p>
          <p><strong>Summary of Who We Share Your Personal Information With</strong></p>
          <table width="100%">
            <thead>
              <tr>
                <td width="28%">
                  <p><strong>Personal Information Category</strong></p>
                </td>
                <td width="36%">
                  <p><strong>Category of Third Parties With Which Personal Information is Shared</strong></p>
                </td>
                <td width="34%">
                  <p><strong>Purpose for Disclosure/Sharing of Personal Information</strong></p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="28%">
                  <p>Personal Information and other identifiers</p>
                </td>
                <td width="36%">
                  <p>Service providers, partners</p>
                </td>
                <td width="34%">
                  <p>General business purposes, including creating, maintaining, customizing and securing your account with us.&nbsp; It is also used for providing customer service, improving our products and services and marketing</p>
                </td>
              </tr>
              <tr>
                <td width="28%">
                  <p>Internet or other similar network activity.</p>
                </td>
                <td width="36%">
                  <p>Internet Service providers</p>
                </td>
                <td width="34%">
                  <p>This information is used for internet browsing analytics</p>
                </td>
              </tr>
              <tr>
                <td width="28%">
                  <p>Sensory data in the form of audio messages.</p>
                </td>
                <td width="36%">
                  <p>Service providers</p>
                </td>
                <td width="34%">
                  <p>General business purposes, including creating, maintaining, customizing and securing your account with us. It is also used for providing customer service</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p><strong>Choices About the Information You Provide Us</strong></p>
          <p>When corresponding with AWR/PHR or our representatives, or making a request for information or otherwise interacting with us through the Site, you choose what information to supply, what questions to pose and comments to make, whether you wish to receive further information and by what method of communication such information should be delivered. Please take care to share only such information as is needed or that you believe is appropriate.</p>
          <p><strong>Data Security</strong></p>
          <p>AWR/PHR takes commercially reasonable measures to secure and protect information transmitted via or stored on the Site and transmitted to and from the Site. Nevertheless, no security system is impenetrable. We cannot and do not guarantee that information that users of the Site may happen to transmit or otherwise supply, or that any communications or any electronic commerce conducted on or through the Site, is or will be totally secure. You agree to immediately notify us of any breach of Site security, this Privacy Policy or the<strong>&nbsp;<a href="https://www.yourhomesolutions.com/pages/terms-and-conditions" target="_blank" rel="noreferrer" title="Terms of Use">Terms of Use</a></strong>&nbsp;of which you become aware.</p>
          <p><strong>Linked Sites</strong></p>
          <p>For your convenience, some hyperlinks may be posted on the Site that link to other websites not under our control. We are not responsible for, and this Privacy Policy does not apply to, the privacy practices of those sites or of any companies that we do not own or control. In addition, should you happen to initiate a transaction on a website that our Site links to, even if you reached that third party website through our Site, the information that you submit to complete that transaction becomes subject to the privacy practices of the operator of that linked website. To understand how personal information that is collected about you is used and protected by third parties, we recommend you read the privacy policy of each website you visit.</p>
          <p><strong>Do Not Track</strong></p>
          <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>
          <p><strong>California and Virginia Privacy Rights</strong></p>
          <p>The following provisions apply to California and Virginia Consumers, who are granted certain rights over their personal data under their respective state’s applicable laws. You have the right to exercise these rights detailed below via an authorized agent who meets the agency requirements of applicable state laws.</p>
          <p>To access or delete specific pieces of personal information we will need to verify your identity to the degree of certainty required by law. A third party authorization form will be required to authorize third parties to make an access or deletion request on your behalf. As permitted by applicable law, any request you submit to us is subject to an identification and residency verification process (“Verifiable Consumer Request”). We will not fulfill your request unless you have provided sufficient information for us to reasonably verify you are the consumer about whom we collected personal information.</p>
          <p><strong>Sale of Personal Information</strong></p>
          <p>We do not “sell” personal information, as the term is defined in the California Consumer Privacy Rights Act, as amended by the California Privacy Rights Act.</p>
          <p><strong>Your Right to Know, Delete, and Correct</strong></p>
          <p>You have the right to request that we disclose certain information to you about our collection and use of your personal data over the past 12 months. Once we receive and validate your request, we will include a list of your personal data that may have been disclosed and the categories of third parties the information may have been disclosed to.&nbsp;</p>
          <p>You may request that we delete any of your personal data that we collected from you and retained, subject to certain exceptions. Once we receive your request and confirm your identity, we will review your request. We may deny your deletion request if retaining the information is proper and necessary or if an exception allowing us to retain the information applies.</p>
          <p>You may also request that we correct any of your personal data that has become outdated or needs correction for any reason.</p>
          <p>If your request is approved, we will delete, correct, or deidentify the relevant information and will direct our service providers to take similar action.</p>
          <p><strong>Exercising Your Rights to Know, Delete or Correct</strong></p>
          <p>California and Virginia residents may exercise their rights to know, delete or correct as described above by submitting a request online by clicking <strong><a href="https://homesolutions.allegiancetech.com/cgi-bin/qwebcorporate.dll?idx=J263TN" title="Customer Privacy Website" target="_blank" rel="noreferrer">here</a>.</strong> Alternatively, you can email us at: <strong><a href="mailto:privacy@oncoursehome.com" target="_blank" rel="noreferrer" >privacy@oncoursehome.com</a></strong>.</p>
          <p><strong>NOTE:</strong> If emailing us please include “Verifiable Consumer Request” in the subject line of the email and your name and address in the body of the email.</p>
          <p>Only you, or someone legally authorized to act on your behalf, may make a request to know or delete related to your personal data.</p>
          <p>You may only submit a request to know twice within a 12-month period. We endeavor to substantively respond to a Verifiable Consumer Request within forty-five (45) days of its receipt, unless we require an extension. If we reasonably require an extension we will inform you of the reason and extension period.</p>
          <p><strong>Non-Discrimination</strong></p>
          <p>We will not discriminate against you for exercising any of your data subject rights.</p>
          <p>We do not charge a fee to process or respond to your Verifiable Consumer Request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
          <p><strong>Californias Shine the Light Law</strong></p>
          <p>California Civil Code Section 1798.83 permits users of our Site that are California residents to request certain information regarding our disclosure of the information you provide through the Site to third parties for their direct marketing purposes. To make such a request, please contact us by email at privacy@oncoursehome.com or at the following address:</p>
          <p>Attn: Legal Counsel</p>
          <p>1415 W. Diehl Rd. Ste 270</p>
          <p>Naperville, IL 60563</p>
          <p>Any such request must include “California Privacy Rights Request” in the first line of the description and include your name, street address, city, state, and ZIP code. Please note that we are only required to respond to one Shine the Light request per consumer each year, and we are not required to respond to requests made by means other than through the e-mail address or mailing address provided in this Policy.</p>
          <p><strong>Participation in Financial Incentive Programs</strong></p>
          <p>We will notify you in the event we offer any financial incentive for the use of your personal information. Please note that participating in incentive programs is entirely optional, you will have to affirmatively opt-in to the program and you can opt-out of each program (i.e., terminate participation and forgo the ongoing incentives) prospectively by following the instructions in the applicable program description and terms.</p>
          <p><strong>Contact Us</strong></p>
          <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please visit the <a href="https://homesolutions.allegiancetech.com/cgi-bin/qwebcorporate.dll?idx=J263TN" target="_blank" rel="noreferrer" title="Customer Privacy Website"><strong>customer privacy website</strong></a> or contact us at: <strong><a href="mailto:privacy@oncoursehome.com">privacy@oncoursehome.com</a></strong>.</p>
        </PrivacyContainer>
      </section>
    );
};

export default Privacy;