import React from 'react';
import { styled } from 'styled-components';
import APPLICATION_CONSTANTS from '../constants/strings';
import Button from './Button';
import { sizes } from '../constants/devices';

const SectionContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;

    @media screen and (max-width: ${sizes.tablet} ) {
       justify-content: flex-start;
    };
`;

const HeroContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: calc((100vw - 1680px) / 2);

    @media screen and (max-width: 1680px) {
        margin-left: 0px;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        grid-template-columns: repeat(1, 1fr);
    };
    @media screen and (min-width: ${sizes.desktop}) {
        margin-left: 12em;
    };
`;

const ImageLeftContainer = styled.div`
    padding: 5em 0em 7.5em 4.8em;
    text-align: center;

    @media screen and (max-width: 1350px) {
        img  {
            width: 100%;
        }
    }
    @media screen and (max-width: ${sizes.laptop}) {

    };
    @media screen and (max-width: ${sizes.tablet}) {
        padding: 5em 1.4em 0em 1.4em;
        padding-bottom: 0px;
    };
`;

const HeroContentLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    justify-content: center;
    margin-bottom: 6.4em;

    h1 {
        max-width: 85%;
        @media screen and (max-width: ${sizes.laptop}) {
            max-width: 90%;
        }
        @media screen and (max-width: ${sizes.tablet}) {
            max-width: 425px;
        }
        @media screen and (max-width: ${sizes.mobileL}) {
            max-width: 100%;
        }
    }
    p {
        max-width: 75%;
    }

    @media screen and (max-width: ${sizes.tablet} ) {
        padding: 6em;
        margin-bottom: 4.8em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
        margin-bottom: 3.2em;
    };
`;

const HeroContainerImageLeft = styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6.4em;

    @media screen and (max-width: ${sizes.tablet} ) {
        grid-template-columns: repeat(1, 1fr);
    };
`;


const HeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    justify-content: center;
    padding: 12em;
    padding-right: 4.5vw;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const MainHeader = styled.h1`
    font-size: clamp(4.4rem, 6vw, 11.5rem);
    line-height: 1em;
    color: var(--oncourse-blue);
    min-width: 270px;

    @media screen and (max-width: ${sizes.tablet}) {
        max-width: 400px;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        max-width: 75%;
        font-size: 4.4rem;
    };
`;

const SubHeader = styled.p`
    font-size: 1.8rem;
    font-weight: 700;
    min-width: 315px;
    max-width: 75%;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 1.6rem;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        max-width: 450px;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        max-width: 85%;
    };
    @media screen and (max-width: ${sizes.mobileM}) {
        font-size: 1.4rem;
        min-width: 0px
    };
    @media screen and (min-width: ${sizes.desktop}) {
        font-size: 2rem;
        max-width: 85%;
    };
`;

const ImageWrapper = styled.div`
    grid-column: 2;
    overflow: hidden;
    position: relative;

    img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: auto;
        border: none;
        max-width: 100%;
        max-height: 100%;
     };

     @media screen and (max-width: ${sizes.tablet}) {
        display: none;
    };
`;

const ButtonWrapper = styled.div`
    margin-top: 3em;
    display: flex;

    @media screen and (max-width: ${sizes.mobileL}) {
        margin-top: 1.2em;
    };
`;

const Arrow = styled.img`
    position: absolute;
    bottom: 0;
    padding-bottom: 3.2em;
    height: 3em;

    @media screen and (max-width: ${sizes.tablet} ) {
        padding-left: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding-left: 2.4em;
        padding-bottom: 2.4em;
    };
`;

const Hero = ({ 
    mainHeader, 
    subHeader, 
    src, 
    layoutType, 
    buttonString,
    path, 
    hasButton,
    altText,
    isHome,
}) => {
    const button = (
        <ButtonWrapper>
            <Button 
                color={APPLICATION_CONSTANTS.COLORS.ONCOURSE_BLUE}
                path={path}
                string={buttonString || APPLICATION_CONSTANTS.CONTACT_US}
            />
        </ButtonWrapper>
    );

    return (
        <SectionContainer>
            {(layoutType === 'ImageRight' || layoutType === String.Empty) && (
                <HeroContainer>
                    <HeaderContainer>
                        <MainHeader>
                            {mainHeader}
                        </MainHeader>
                        <SubHeader>
                            {subHeader}
                        </SubHeader>
                        {hasButton && button}
                    </HeaderContainer>
                    <ImageWrapper>
                        <img src={src} alt={altText} />
                    </ImageWrapper>
                </HeroContainer>
            )}
             {layoutType === 'ImageLeft' && (
                <HeroContainerImageLeft>
                    <ImageLeftContainer>
                        <img src={src} alt={altText} />
                    </ImageLeftContainer>
                    <HeroContentLeft>
                        <MainHeader>
                            {mainHeader}
                        </MainHeader>
                        <SubHeader>
                            {subHeader}
                        </SubHeader>
                    </HeroContentLeft>
                </HeroContainerImageLeft>
            )}
            {isHome && <Arrow src={'/images/arrow-down.svg'} alt='' />}
        </SectionContainer>
    );
};

export default Hero;