import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';
import { useMediaQuery } from 'react-responsive';

const LogoGridContainer = styled.section`
    padding: 12em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5.4em;
    background-color: var(--background-gray);

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const MainHeader = styled.h2`
    font-size: 4.8rem;
    text-align: center;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
`;

const IconGridContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4em;
    max-width: 1000px;
    margin: auto;
`;

const IconGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.4em;

    // @media screen and (max-width: 1250px) {
    //     max-width: 790px;
    // };
    @media screen and (max-width: 710px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

    };
`;

const Icon = styled.img`
    @media screen and (max-width: 510px) {
        height: 100px;
    };
    @media screen and (max-width: ${sizes.mobileM}) {
        height: 90px;
    };
`;

const LogoGrid = ({ strings }) => {
    const isTwoColumn = useMediaQuery({ query: '(max-width: 710px)' });
    const icons = strings.ICONS.map((icon, index) => <Icon key={`icon-${index}`} src={icon} alt={''} />);
    const iconSet1 = icons.slice(0, strings.ICONS.length / 2);
    const iconSet2 = icons.slice((strings.ICONS.length / 2));

    const doubleRow = (
        <IconGridContainer>
            <IconGrid>
                {iconSet1}
            </IconGrid>
            <IconGrid>
                {iconSet2}
            </IconGrid>
        </IconGridContainer>
    );

    const singleRow = (
        <IconGridContainer>
            <IconGrid>
                {icons}
            </IconGrid>
        </IconGridContainer>
    );

    return (
        <LogoGridContainer>
            <MainHeader>
                {strings.HEADER}
            </MainHeader>
            {isTwoColumn ? singleRow : doubleRow}
        </LogoGridContainer>
    );
};

export default LogoGrid;