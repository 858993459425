import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const ThinCardContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    gap: 2.4em;
    max-width: 1440px;
    margin: auto;
    position: relative;

    @media screen and (max-width: ${sizes.tablet}) {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-flow: row;
    };
`;

const Card = styled.div`
    display: flex;
    align-items: ${props => props.$hasSubheader ? 'flex-start' : 'center'};
    gap: 4.8em;
    padding: 2em 4em;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-color: white;
        border-radius: 18px;
    }

    @media screen and (max-width: ${sizes.tablet}) {
        padding: 2em 2.4em;
        gap: 3.2em;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        padding: 2em;
        gap: 2em;
    };
`;

const Number = styled.div`
    font-size: 9rem;
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    color: var(--oncourse-blue);
    line-height: 0.9em;
    position: relative;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 6rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        line-height: 1em;
        font-size: 4.4rem;
    };
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    position: relative;
`;

const Header = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
`;

const SubHeader = styled.p`
    font-size: 1.4rem;
`;

const ThinCardLayout = ({ cardStrings }) => {
    const cards = cardStrings.map((card, index) => {
        const subHeader = (
            <SubHeader>
                {card?.SUB_HEADER}
            </SubHeader>
        );

        return (
            <Card 
                key={`card-${index}-thin`} 
                $hasSubheader={card.SUB_HEADER}
            >
                <Number>
                    {index + 1}
                </Number>
                <TextContainer>
                    <Header>
                        {card.MAIN_HEADER}
                    </Header>
                    {card.SUB_HEADER && subHeader}
                </TextContainer>
            </Card>
        );
    });

    return (
        <ThinCardContainer>
            {cards}
        </ThinCardContainer>
    );
};

export default ThinCardLayout;