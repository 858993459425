import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const MulticolorGridContainer = styled.section`
    padding: 12em;
    display: flex;
    flex-direction: column;
    gap: 6.4em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        gap: 4em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const Header = styled.h2`
    text-align: center;
    font-size: 4.8rem;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
    
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2.4em;
    max-width: 1440px;
    margin: auto;

    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
    };
`;

const Card = styled.div`
    border-radius: 32px;
    padding: 3.2em 2.4em;
    background-color: ${props => props.$backgroundColor};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3.2em;
    color: ${props => props.$textColor};
    min-height: 20em;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 550px) {
        min-height: 10em;
    };
`;

const CardTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    max-width: 60%;
    z-index: 1;

    @media screen and (max-width: 1250px) {
        max-width: 100%;
    };
`;

const CardHeader = styled.h3`
    font-size: 3.2rem;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 2.8rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 2.4rem;
    };
`;

const CardContent = styled.p`
    font-size: 1.6rem;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 1.4rem;
    };
`;

const NavigationLink = styled(Link)`
    font-size: 1.6rem;
    border-bottom: 2px solid ${props => props.$underlineColor};
    padding: 0.1em 0em;
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    letter-spacing: .1rem;
    text-decoration: none;
    color: inherit;

    &:hover {
        opacity: 0.8;
    };
`;

const LinkWrapper = styled.div``;

const ImageWrapper = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 0;
    display: ${props => props.$isLaptop && 'none'};
`;

const MulticolorGrid = ({ headerString, cardStrings }) => {
    const isLaptop = useMediaQuery({ query: '(max-width: 1250px)' });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    };

    const cards = cardStrings.map((card, index) => {
        return (
            <Card
                key={`multicolor-card-${index}`}
                $backgroundColor={card.BACKGROUND_COLOR}
                $textColor={card.TEXT_COLOR}
            >
                <CardTextContainer>
                    <CardHeader>
                        {card.HEADER}
                    </CardHeader>
                    <CardContent>
                        {card.CONTENT}
                    </CardContent>
                </CardTextContainer>
                <LinkWrapper>
                    <NavigationLink 
                        to={card.HREF}
                        onClick={handleClick}
                        $underlineColor={card.TEXT_COLOR}
                    >
                        {card.LINK}
                    </NavigationLink>
                </LinkWrapper>
                <ImageWrapper src={card.SRC} alt={card.ALT} $isLaptop={isLaptop} />
            </Card>
        );
    });

    return (
        <MulticolorGridContainer>
            <Header>
                {headerString}
            </Header>
            <GridContainer>
                {cards}
            </GridContainer>
        </MulticolorGridContainer>
    );
};

export default MulticolorGrid;