import React from 'react';
import { styled } from 'styled-components';
import APPLICATION_CONSTANTS from '../constants/strings';
import Button from './Button';
import { sizes } from '../constants/devices';

const BannerContainer = styled.section`
    background-color: var(--oncourse-blue);
`;

const HeaderContainer = styled.header`
    max-width: 1440px;
    margin: auto;
    padding: 8em 12em;
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    color: white;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const MainHeader = styled.h1`
    font-size: 4.8rem;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 4rem;
    };
    @media screen and (min-width: ${sizes.laptopL}) {
        font-size: ${props => props.size === "large" && '5.8rem'};
    };
`;

const SubHeader = styled.p`
    font-size: ${props => props.size === "large" ? '2.4rem' : '1.8rem'};
    font-weight: 700;
    max-width: 80%;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 1.8rem;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 1.6rem;
    };
`;

const ButtonWrapper = styled.div`
    margin-top: 3em;
    display: flex;
`;

const CTABanner = ({ path, type, size, strings, buttonString }) => {
    let string;
    if (buttonString) {
        string = buttonString;
    }
    else {
        string = type === APPLICATION_CONSTANTS.LEARN_MORE ?
            APPLICATION_CONSTANTS.LEARN_MORE : 
            APPLICATION_CONSTANTS.CONTACT_US
    }
    return (
        <BannerContainer>
            <HeaderContainer>
                <MainHeader size={size}>
                    {strings.MAIN_HEADER}
                </MainHeader>
                <SubHeader size={size}>
                    {strings.SUB_HEADER}
                </SubHeader>
                <ButtonWrapper>
                    <Button 
                        color={APPLICATION_CONSTANTS.COLORS.WHITE}
                        path={path}
                        string={string}
                    />
                </ButtonWrapper>
            </HeaderContainer>
        </BannerContainer>
    );
};

export default CTABanner;