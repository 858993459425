import React from 'react';
import { styled } from 'styled-components';
import ColumnLayout from './ColumnLayout';
import { sizes } from '../constants/devices';

const BannerContainer = styled.section`
    background-color: ${props => props.$styles.backgroundColor};
    padding: 12em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const Header = styled.h2`
    text-align: center;
    font-size: 4.8rem;
    margin-bottom: 1.3em;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 4rem;
        margin-bottom: 1.4em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
`;

const LargeHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.2em;
    width: 100%;
    padding-bottom: 8em;
    color: ${props => props.$styles.$backgroundColor === 'white' ? 'black' : 'white'};
    max-width: 1440px;
    margin: auto;

    @media screen and (max-width: ${sizes.tablet}) {
        gap: 1.2em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding-bottom: 3.2em;
    };
`;

const LargeHeaderMainHeader = styled.h2`
    font-size: 4.8rem;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
`;

const LargeHeaderSubHeader = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
    max-width: 80%;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 1.6rem;
        max-width: 100%
    };
    @media screen and (max-width: ${sizes.mobileL}) {

    };
`;

const ModularBanner = ({ columnHeaderColor, headerString, subHeaderString, contentStrings, styles, type }) => {
    const standardHeader = <Header>{headerString}</Header>;
    const largeHeader = (
        <LargeHeaderContainer $styles={styles}>
            <LargeHeaderMainHeader>
                {headerString}
            </LargeHeaderMainHeader>
            <LargeHeaderSubHeader>
                {subHeaderString}
            </LargeHeaderSubHeader>
        </LargeHeaderContainer>
    );
    
    return (
        <BannerContainer $styles={styles}>
            {type === "standard" && standardHeader}
            {type === "large" && largeHeader}
            <ColumnLayout columnHeaderColor={columnHeaderColor} strings={contentStrings} styles={styles} />
        </BannerContainer>
    );
};

export default ModularBanner;