import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const StatsContainer = styled.section`
    padding: 12em;
    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const StatsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 1440px;
    margin: auto;

    @media screen and (max-width: ${sizes.tablet}) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 4.8em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        grid-template-columns: repeat(1, 1fr);
    };
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0em clamp(4.8em, 5vw, 7.2em);
    position: relative;
    ${props => props.$index === 0 && 'padding-left: 0em;'}
    ${props => props.$index === 3 && 'padding-right: 0em;'}
    border-right: ${props => props.$index < 3 && '1px solid black'};


    @media screen and (max-width: ${sizes.tablet}) {
        border-right: none; 
        padding: 0em;
        ${props => props.$index < 2 ? 'padding-bottom: 4.8em' : 'padding-top: 4.8em'};
        ${props =>  props.$index > 1 && 
            `&:before {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                width: 30%;
                left: 35%;
                border-bottom: 1px solid black;
            }`
        }
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        ${props => (props.$index > 0 && props.$index < 3) && 'padding: 4.8em 0em'};
        ${props =>  props.$index > 0 && 
            `&:before {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                width: 20%;
                left: 40%;
                border-bottom: 1px solid black;
            }`
        }
    };
`;

const Header = styled.h2`
    font-size: clamp(4.4rem, 7vw, 9rem);
    color: var(--oncourse-blue);
    white-space: nowrap;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: clamp(3.2rem, 5vw, 9rem);
    };
    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 4.4rem;
    };
`;

const SubHeader = styled.p`
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    max-width: 100%;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 1.6rem;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        max-width: 75%;
    };
    @media screen and (max-width: 585px) {
        max-width: 95%;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        max-width: 60%;
    };
`;

const Stats = ({ statStrings }) => {
    const stats = statStrings.map((stat, index) => {
        return (
            <TextContainer key={`stat-${index}`} $index={index}>
                <Header>
                    {stat.MAIN_HEADER}
                </Header>
                <SubHeader>
                    {stat.SUB_HEADER}
                </SubHeader>
            </TextContainer>
        );
    });

    return (
        <StatsContainer>
            <StatsWrapper>
                {stats}
            </StatsWrapper>
        </StatsContainer>
    );
};

export default Stats;