import React, { useState, useEffect } from 'react';
import { styled, css } from 'styled-components';
import APPLICATION_CONSTANTS from '../constants/strings';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '../constants/devices';
import Button from './Button';
import MobileNav from './MobileNav';
import { NavLink, useLocation } from 'react-router-dom';

const NavContainer = styled.div`
    border-bottom: 1px solid var(--background-gray);
    box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.07);
    background-color: white;
    z-index: 999;
    position: sticky;
    top: 0;
    max-height: 110px;

    ${props => props.$isVisible && css`
        box-shadow: none;
    `};

    @media screen and (max-width: ${sizes.tablet}) {
        box-shadow: 0px 6px 11px -6px rgba(0, 0, 0, 0.07);

        ${props => props.$isVisible && css`
            box-shadow: none;
        `};
    };
`;

const ContentWrapper = styled.div`
    padding: 2em 12em;
    max-width: 1440px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 2em 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 1.6em 2.4em;

        img {
            max-height: 50px;
        };
    };
`;

const Options = styled.div`
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 3.6em;
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0;

    .nav-link {
        color: black;
    };
    .nav-link.active {
        color: var(--oncourse-blue) !important;
    };
    .nav-link:hover {
        color: var(--oncourse-blue) !important;
    };
`;

const ImageWrapper = styled.img`
    cursor: pointer;
`;

const Nav = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const location = useLocation();
    const isLaptop = useMediaQuery({ query: '(max-width: 1400px)' });
    const [show, setShow] = useState(true);

    const controlNavbar = () => {
        window.scrollY > 0 ? setShow(false) : setShow(true);
    }
    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)

        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    }, []);

    useEffect(() => {
        setMobileNavOpen(false);
      }, [location]);

    const handleChange = (e) => {
        setMobileNavOpen(!isMobileNavOpen);
    };
    
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const options = APPLICATION_CONSTANTS.NAV_OPTIONS.map(option => {
        const path = '/' + option.toLocaleLowerCase().split(' ').join('-');
        return (
            <NavLink
                key={option.toLocaleLowerCase()}
                className={'nav-link'}
                to={path}
                onClick={handleClick}
                style={({ isActive }) => {
                    return {
                        color: isActive ? "var(--oncourse-blue) !important" : "black",
                        borderBottom: isActive ? "3px solid var(--oncourse-blue)" : "none",
                        textDecoration: "none",
                    };
                }}
            >
                {option}
            </NavLink>
        );
    });

    const OptionsComponent = (
        <Options>
            {options}
            <Button 
                color={APPLICATION_CONSTANTS.COLORS.ONCOURSE_BLUE}
                string={APPLICATION_CONSTANTS.CONTACT_US}
            />
        </Options>
    );

    const logoNavLink =(
        <NavLink to={'/'} onClick={handleClick}>
            <ImageWrapper src={'/images/logo-sm.png'} alt="logo" height="60" />
        </NavLink>
    );

    return (
        <NavContainer $isVisible={show}>
            <ContentWrapper>
                {(isMobileNavOpen && isLaptop) && <MobileNav handleChange={handleChange} />}
                {logoNavLink}
                {isLaptop ? <ImageWrapper src={'/images/burger-menu-icon.svg'} onClick={handleChange} alt="burger menu" /> : OptionsComponent}
            </ContentWrapper>
        </NavContainer>
    );
};

export default Nav;