import React, { useState } from 'react';
import { styled } from 'styled-components';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSpring, animated } from "@react-spring/web";
import { sizes } from '../constants/devices';

const AccordionContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
    gap: 2.4em;
    width: 100%;
    max-width: 85em;
    padding: 3.2em 3em;
    border-bottom: 1px solid black;

    @media screen and (max-width: ${sizes.tablet}) {
        padding: 2.4em 1em;
    };
`;

const ExpandableHeaderContainer = styled.div`
    display: flex;
    justify-content: start;
    cursor: pointer;
    align-items: center;

    &:hover {
        color: var(--oncourse-blue);
    };
`;

const Image = styled.img`
    width: 50px;
    margin-right: 30px;
`;

const MainHeader = styled.p`
    color: ${props => props.$isOpen ? 'var(--oncourse-blue)' : 'black'};
    flex: 1;
    font-weight: 700;
    font-size: 2.4rem;
    padding-right: 1.8em;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 2rem;
    };
`;

const Content = styled(animated.div)`
    display: ${props => props.$isOpen ? 'block' : 'none'};
    font-weight: 700;
    max-width: 90%;
    font-size: 1.8rem;
`;

const BulletedList = styled.ul`
    padding-left: 3.2em;
    margin: 1.2em 0em 0.6em 0em;

    @media screen and (max-width: ${sizes.tablet}) {
        padding-left: 2.4em;
    };
`;

const IconWrapper = styled(animated.i)`
    display:flex;
    align-items: center;
    justify-content: center;
`;

const AccordionSection = ({ accordionStringSet }) => {
    const [open, setOpen] = useState(false);
    const handleChange = (e) => {
        setOpen(!open);
    }
    
    const openAccordionAnimation = useSpring({
        from: { maxHeight: "12em" },
        to: { maxHeight: open ? "9999em" : "12em" },
        config: { duration: "300"}
    });

    const openContentAnimation = useSpring({
        from: { opacity: "0", display: "none" },
        to: { opacity: open ? "1" : "0", display: open ? "block" : "none" },
        config: { duration: "300"}
    });

    const iconAnimation = useSpring({
        from: {
          transform: "rotate(0deg)",
          color: "black"
        },
        to: {
          transform: open ? "rotate(180deg)" : "rotate(0deg)",
          color: open ? "var(--oncourse-blue)" : "black"
        },
        config: { duration: "120" }
      });
    
      const accordionContent = accordionStringSet.CONTENT.map((content, index) => {
        const hasBullets = content.BULLETS;
        const allBullets = content.BULLETS?.map((bullet, bulletIndex) => {
            return <li key={`bullet-${bulletIndex}`}>{bullet}</li>
        });
        const bulletedList = (
            <BulletedList>
                {allBullets}
            </BulletedList>
        );

        return (
            <Content 
                key={`accordion-content-${index}`} 
                $isOpen={open} 
                style={openContentAnimation}
            >
                {hasBullets ? content.TEXT : content}
                {hasBullets && bulletedList}
            </Content>
        );
      });

    return (
        <AccordionContainer style={openAccordionAnimation} >
            <ExpandableHeaderContainer onClick={handleChange} >
                {accordionStringSet.SRC && <Image
                    alt={accordionStringSet.MAIN_HEADER}
                    src={accordionStringSet.SRC}
                />}
                <MainHeader $isOpen={open}>
                    {accordionStringSet.MAIN_HEADER}
                </MainHeader>
                <IconWrapper style={iconAnimation}>
                    <ExpandMoreIcon fontSize={"large"}/>
                </IconWrapper>
            </ExpandableHeaderContainer>
            {accordionContent}
        </AccordionContainer>
    );
};

export default AccordionSection;