import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const TextGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4em;
    padding: 12em;
    max-width: 1440px;
    margin: auto;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        gap: 4.8em;
    };
    @media screen and (max-width: 565px) {
        grid-template-columns: repeat(1, 1fr);
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
        gap: 4em;
    };
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4em;
`;

const Header = styled.h2`
    font-size: 4.8rem;
    max-width: 85%;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 3.4rem;
    };
`;

const SubHeader = styled.p`
    font-size: 1.8rem;
    font-weight: 700;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 1.6rem;
    };
`;

const TextGrid = ({ strings }) => {
    return (
        <TextGridContainer>
                <TextContainer>
                    <Header>
                        {strings.MAIN_HEADER}
                    </Header>
                </TextContainer>
                <TextContainer>
                    <SubHeader>
                        {strings.SUB_HEADER1}
                    </SubHeader>
                    <SubHeader>
                        {strings.SUB_HEADER2}
                    </SubHeader>
                </TextContainer>
        </TextGridContainer>
    );
};

export default TextGrid;