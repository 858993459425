import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const ImageTextV2Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8em;
    max-width: 1440px;
    margin: auto;
    position: relative;

    @media screen and (max-width: ${sizes.tablet}) {
        gap: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        gap: 4.8em;
    };
`;

const ImageText = styled.div`
    display: flex;
    justify-content: ${props => props.$imgLeft ? 'flex-start' : 'flex-end'};
    gap: 10.6em;

    @media screen and (max-width: 1200px) {
        flex-direction: ${props => props.$imgLeft ? 'column' : 'column-reverse'};
        gap: 6em;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        gap: 4em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        gap: 3.2em;
    };
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.6em;
    flex: 1;
`;

const ImageWrapper = styled.img`
    border-radius: 32px;
    width: 100%;
    flex: 1;
`;

const Header = styled.h3`
    font-size: 1.6rem;
`;

const SubHeader = styled.p`
    font-size: 2.8rem;
    font-weight: 700;
    max-width: 80%;

    @media screen and (max-width: 1200px) {
        max-width: 90%;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 2.4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        max-width: 100%;
    };
`;

const ImageTextV2 = ({ strings }) => {
    return (
        <ImageTextV2Container>
            <ImageText $imgLeft={true}>
                <ImageWrapper src={strings[0].SRC} alt={'two people shaking hands'} />
                <TextContainer>
                    <Header>
                        {strings[0].HEADER1}
                    </Header>
                    <SubHeader>
                        {strings[0].SUB_HEADER1}
                    </SubHeader>
                </TextContainer>
            </ImageText>
            <ImageText>
                <TextContainer>
                    <Header>
                        {strings[1].HEADER2}
                    </Header>
                    <SubHeader>
                        {strings[1].SUB_HEADER2}
                    </SubHeader>
                </TextContainer>
                <ImageWrapper src={strings[1].SRC} alt={'a family enjoying dinner together'} />
            </ImageText>
        </ImageTextV2Container>
    );
};

export default ImageTextV2;