import React, { useEffect, useRef} from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const StepsContainer = styled.section`
    padding: 12em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6.8em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        gap: 4.8em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
        gap: 3.2em;
    };
`;

const Header = styled.h2`
    font-size: 4.8rem;
    font-weight: 700;
    text-align: center;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 3.4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        max-width: 22.5rem;
    };
`;

const StepsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2.4em;
    max-width: 1440px;
    margin: auto;

    @media screen and (max-width: ${sizes.tablet}) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 4.8em;
    };
`;

const StepCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4em;
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 1.8rem;
    gap: 0.6em;
`;

const Content = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
`;

const MainImage = styled.img`
    width: 100%;
`;

const NumberImage = styled.img`
    hieght: 5vw;
`;

const ImageWrapper = styled.div``;

const Steps = ({ headerString, steps }) => {
    const stepRef1 = useRef(null);
    const stepRef2 = useRef(null);
    const stepRef3 = useRef(null);
    const containerRef1 = useRef(null);
    const containerRef2 = useRef(null);
    const containerRef3 = useRef(null);

    useEffect(() => {
        const step1 = stepRef1?.current;
        const step2 = stepRef2?.current;
        const step3 = stepRef3?.current;
        const container1 = containerRef1?.current;
        const container2 = containerRef2?.current;
        const container3 = containerRef3?.current;

        if (!step1 || !step2 || !step3) return;
    
        const observer = new ResizeObserver(() => {
          if(step1.clientHeight > 25) {
            container1.style.alignItems = 'flex-start';
          }
          if(step2.clientHeight > 25) {
            container2.style.alignItems = 'flex-start';
          }
          if(step3.clientHeight > 25) {
            container3.style.alignItems = 'flex-start';
          }
          if(step1.clientHeight < 26) {
            container1.style.alignItems = 'center';
          }
          if(step2.clientHeight < 26) {
            container2.style.alignItems = 'center';
          }
          if(step3.clientHeight < 26) {
            container3.style.alignItems = 'center';
          }
        });
    
        observer.observe(step1);
        observer.observe(step2);
        observer.observe(step3);
        observer.observe(container1);
        observer.observe(container2);
        observer.observe(container3);

        return () => {
          observer.disconnect();
        };
      }, []);


    return (
        <StepsContainer>
            <Header>
                {headerString}
            </Header>
            <StepsGrid>
                <StepCard>
                    <ImageWrapper>
                        <MainImage src={steps[0].SRC} alt={'person relaxing on a bean bag looking at their phone'} />
                    </ImageWrapper>
                    <ContentContainer ref={containerRef1}>
                        <NumberImage src={steps[0].NUM_SRC} alt={''} />
                        <Content ref={stepRef1}>
                            {steps[0].CONTENT}
                        </Content>
                    </ContentContainer>
                </StepCard>
                <StepCard>
                    <ImageWrapper>
                        <MainImage src={steps[1].SRC} alt={'two people relaxing on a yellow couch'} />
                    </ImageWrapper>
                    <ContentContainer ref={containerRef2}>
                        <NumberImage src={steps[1].NUM_SRC} alt={''} />
                        <Content ref={stepRef2}>
                            {steps[1].CONTENT}
                        </Content>
                    </ContentContainer>
                </StepCard>
                <StepCard>
                    <ImageWrapper>
                        <MainImage src={steps[2].SRC} alt={'smiling technician in a blue uniform'} />
                    </ImageWrapper>
                    <ContentContainer ref={containerRef3}>
                        <NumberImage src={steps[2].NUM_SRC} alt={''} />
                        <Content ref={stepRef3}>
                            {steps[2].CONTENT}
                        </Content>
                    </ContentContainer>
                </StepCard>
                {/* {cards} */}
            </StepsGrid>
        </StepsContainer>
    );
};

export default Steps;