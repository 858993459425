import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const EBRGsContainer = styled.section`
    padding: 12em;
    display: flex;
    flex-direction: column;
    gap: 6em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: auto;
`;

const MainHeader = styled.p`
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    font-size: 3.2rem;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 2.8rem;
    };
`;

const Subheader = styled.div`
    align-self: center;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    max-width: 80%;

    @media screen and (max-width: ${sizes.tablet}) {
        max-width: none;
    };
`;

const IconGrid = styled.div`
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    gap: 8em;

    @media screen and (max-width: ${sizes.tablet}) {
        gap: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 2.4rem;
        gap: 2.4em;
    };
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4em;

    @media screen and (max-width: ${sizes.mobileL}) {
        gap: 1em;
        padding-bottom: 1em;
    };
`;

const Icon = styled.img`
    height: 150px;
    width: auto;
`;
const Label = styled.div`
    align-self: center;
    font-size: clamp(1.6rem, 1vw, 1.8rem);
    text-align: center;
    max-width: 80%;

    @media screen and (max-width: ${sizes.tablet}) {
        max-width: none;
    };
`;

const EBRGs = ({ strings }) => {
    const icons = strings.EBRGS.map(icon => {
        return (
            <IconContainer key={icon.MAIN_HEADER.split(' ').join('-')}>
                <Icon src={icon.SRC} alt={icon.MAIN_HEADER} />
                {/* <MainHeader>
                    {icon.MAIN_HEADER}
                </MainHeader> */}
                {/* <Label>
                    {icon.LABEL}
                </Label> */}
            </IconContainer>
        );
    });

    return (
        <EBRGsContainer>
            <HeaderContainer>
                <MainHeader>
                    {strings.HEADER}
                </MainHeader>
            </HeaderContainer>
            <IconGrid>
                {icons}
            </IconGrid>
            
            <Subheader>{strings.SUB_HEADER}</Subheader>
            <Label>{strings.DESC}</Label>
        </EBRGsContainer>
    );
};

export default EBRGs;