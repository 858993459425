import React from 'react';
import APPLICATION_CONSTANTS from './constants/strings';
import Hero from './shared/Hero';
import SimpleBanner from './shared/SimpleBanner';
import ModularBanner from './shared/ModularBanner';
import CTABanner from './shared/CTABanner';
import Stats from './shared/Stats';
import GradientBanner from './shared/GradientBanner';
import Protections from './shared/Protections';
import LogoGrid from './shared/LogoGrid';

const Partners = () => {
    return (
        <div>
            <Hero 
                mainHeader={APPLICATION_CONSTANTS.PARTNERS_HERO.MAIN_HEADER}
                subHeader={APPLICATION_CONSTANTS.PARTNERS_HERO.SUB_HEADER}
                hasButton
                src={'/images/oncoursehome_site_graphic_partners__v2.png'}
                altText={'two people in business attire standing next to each other'}
            />
            <SimpleBanner string={APPLICATION_CONSTANTS.PARTNERS_SIMPLE_BANNER}/>
            <Stats statStrings={APPLICATION_CONSTANTS.PARTNERS_STATS_BANNER}/>
            <ModularBanner 
                type={"large"}
                headerString={APPLICATION_CONSTANTS.PARTNERS_MODULARBANNER.HEADER.MAIN_HEADER} 
                subHeaderString={APPLICATION_CONSTANTS.PARTNERS_MODULARBANNER.HEADER.SUB_HEADER}
                contentStrings={APPLICATION_CONSTANTS.PARTNERS_MODULARBANNER.COLUMNS}
                styles={{
                    backgroundColor: 'var(--oncourse-blue)'
                }}
            />
            <GradientBanner 
                headerString={APPLICATION_CONSTANTS.PARTNERS_GRADIENT_BANNER.HEADER}
                cardStrings={APPLICATION_CONSTANTS.PARTNERS_GRADIENT_BANNER.CARDS}
                type={"thin"}
            />
            <LogoGrid strings={APPLICATION_CONSTANTS.PARTNERS_LOGO_BANNER}/>
            <Protections strings={APPLICATION_CONSTANTS.PARTNERS_PROTECTIONS_BANNER}/>
            <SimpleBanner type={"quote"} string={APPLICATION_CONSTANTS.PARTNERS_SIMPLE_QUOTE_BANNER} />
            <CTABanner size={"large"}  strings={APPLICATION_CONSTANTS.PARTNERS_CTA_BANNER} />
        </div>
    );
};

export default Partners;